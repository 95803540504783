import useProductListingData from 'hooks/useProductListingData';
import { GET_CATEGORY_PAGE } from 'mocks/queries';
import getVisitorLocationQueryVariables from 'commons/getVisitorLocationQueryVariables';
import useUserLocation from 'global-state/userLocation/useUserLocation';

const useCategoryPageData = ({ slug }) => {
  const userLocation = useUserLocation();
  const zipCode = userLocation?.zip ?? '';

  const pageData = useProductListingData(GET_CATEGORY_PAGE, {
    variables: {
      slug,
      zipCode,
      ...getVisitorLocationQueryVariables(userLocation?.stateShortName),
    },
  });

  return pageData;
};

export default useCategoryPageData;
