'use client';

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import notFoundNewConsumerApp from 'commons/notFoundNewConsumerApp';

import ProductListingPage from 'components/consumer/ProductListingPage';
import useCategoryPageData from './hooks/useCategoryPageData';
import SuspenseWithLoader from '../SuspenseWithLoader';
import ProductListingPagePlaceholder from '../ProductListingPage/ProductListingPagePlaceholder';

const stripLeadingAndTrailingSlash = path => {
  const pathWithoutLeadingSlash = path.startsWith('/') ? path.slice(1) : path;

  const pathWithoutTrailingSlash = pathWithoutLeadingSlash.endsWith('/')
    ? pathWithoutLeadingSlash.slice(0, -1)
    : pathWithoutLeadingSlash;

  return pathWithoutTrailingSlash;
};

// Note: The CategoryPageWithData component is separated from the CategoryPage
// component so it can be keyed by slug to ensure that fresh data is rendered
// for each category page.
const CategoryPageWithData = ({ sidebarHeader, slug }) => {
  const pageData = useCategoryPageData({ slug });
  useEffect(() => {
    if (pageData && !pageData?.loading && !pageData?.pageId)
      notFoundNewConsumerApp();
  }, [pageData]);
  return (
    <ProductListingPage pageData={pageData} sidebarHeader={sidebarHeader} />
  );
};

CategoryPageWithData.propTypes = {
  sidebarHeader: PropTypes.node,
  slug: PropTypes.string,
};

const CategoryPage = ({ sidebarHeader }) => {
  const location = useLocation();
  const pathname = location?.pathname || '';
  const slug = stripLeadingAndTrailingSlash(pathname);

  return (
    <SuspenseWithLoader fallback={<ProductListingPagePlaceholder />} key={slug}>
      <CategoryPageWithData
        key={slug}
        sidebarHeader={sidebarHeader}
        slug={slug}
      />
    </SuspenseWithLoader>
  );
};

CategoryPage.propTypes = {
  sidebarHeader: PropTypes.node,
};

export default CategoryPage;
